$color-fon: rgba(238, 229, 222, 1);
$color-565656: rgba(56, 56, 56, 1);
$color-841:rgba(8, 4, 1, 1);
$color-1107233: rgba(110, 72, 33, 1);
$color-20911126:rgba(209, 111, 26, 1);
$color-010717: rgba(0, 107, 17, 1);

$px6: 0.2808rem;
$px10: 0.468rem;
$px12: 0.5616rem;
$px14: 0.6552rem;
$px16: 0.7488rem;
$px18: 0.8424rem;
$px20: 0.936rem;
$px24: 1.1232rem;
$px30: 1.404rem;
$px60:2.808rem;

/*Мобилка*/
$px60mob: 16.8rem;
$px50mob: 14rem;
$px40mob: 11.2rem;
$px30mob: 8.4rem;
$px24mob: 6.72rem;
$px20mob: 5.6rem;
$px18mob: 5.04rem;
$px16mob: 4.48rem;
$px14mob: 3.92rem;
$px10mob: 2.8rem;
